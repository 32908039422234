<template>
  <div>
    <title-bar :title-stack="titleStack" :volunteerId="this.volunteerId" />
    <hero-bar>
      <div class="buttons">
        <b-button class="button is-primary" @click="handleAddVolunteerActivity">
          Adauga activitate
        </b-button>
        <b-button
          :disabled="isDisabled"
          class="button is-info"
          @click.prevent="addDayOffModal()"
        >
          Adauga zi de concediu
        </b-button>
      </div>
    </hero-bar>

    <b-modal :active.sync="addDayOffisModalActive" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirmare actiune</p>
        </header>
        <section class="modal-card-body">
          <p>
            8 ore vor fi scazute din numarul de ore disponibile.
            <b> {{ trashObjectName }} </b>
          </p>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="trashCancel">
            Anulare
          </button>
          <button class="button is-danger" @click="addDayOffConfirm">
            Confirmare
          </button>
        </footer>
      </div>
    </b-modal>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Activitati"
        icon="clock-outline"
      >
        <b-table
          :checked-rows.sync="checkedRows"
          :loading="isLoading"
          :paginated="paginated"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="activities"
        >
          <b-table-column label="Data inceput" field="startDate" v-slot="props">
            {{ props.row.startDateTime | formatDateTime }}
          </b-table-column>
          <b-table-column label="Data sfarsit" field="endDate" v-slot="props">
            {{ props.row.endDateTime | formatDateTime }}
          </b-table-column>
          <b-table-column label="Ore" field="hours" sortable v-slot="props">
            {{ props.row.hours }}
          </b-table-column>
          <b-table-column
            label="Descriere  "
            field="description"
            v-slot="props"
          >
            {{ props.row.description }}
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <router-link v-if="props.row.activityTypeId === 1"
                :to="`/volunteers/${volunteerId}/activities/${props.row.id}`"
                class="button is-small is-primary"
              >
                <b-icon icon="file-edit" size="is-small"
              /></router-link>
              <button
                class="button is-small is-danger"
                type="button"
                @click.prevent="trashPreemptionModal(props.row)"
              >
                <b-icon icon="trash-can" size="is-small" />
              </button>
            </div>
          </b-table-column>
          <template #footer>
            <th>
              <div class="th-wrap">Ore voluntariat total: {{ totalHours }}</div>
            </th>
            <th>
              <div class="th-wrap">
                Zile de concediu primite: {{ numberOfDaysOff }}
              </div>
            </th>
            <th>
              <div class="th-wrap is-centered">
                Ore disponibile: {{ balance }}
              </div>
            </th>
            <th>
              <div></div>
            </th>
          </template>
        </b-table>
      </card-component>
      <b-field grouped>
        <div class="control">
          <b-button type="is-info is-outlined" @click.prevent="cancel()"
            >Inapoi</b-button
          >
        </div>
      </b-field>
    </section>
  </div>
</template>
<script>
import ModalBox from "@/components/ModalBox";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
import CardComponent from "@/components/CardComponent";
import { pathOr } from "ramda";
import moment from "moment";
import Volunteering from "../services/volunteering.service";

export default {
  name: "VolunteerActivities",
  components: {
    ModalBox,
    TitleBar,
    HeroBar,
    CardComponent,
  },
  props: {
    checkable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberOfDaysOff: 0,
      balance: 0,
      totalHours: 0,
      volunteer: {},
      activities: [],
      volunteerId: 0,
      isModalActive: false,
      addDayOffisModalActive: false,
      trashObject: null,
      addDayOffObject: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: [],
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
      ],
    };
  },
  computed: {
    isDisabled() {
      if (this.balance >= 8) {
        return null;
      }
      return "disabled";
    },
    titleStack() {
      return [
        "Registru Voluntariat",
        `${this.volunteer.firstName} ${this.volunteer.lastName}`,
        "Activitati",
      ];
    },
    addDayOffObjectName() {
      if (this.addDayOffObject) {
        return this.addDayOffObject.name;
      }
      return null;
    },
    trashObjectName() {
      if (this.trashObject) {
        return this.trashObject.name;
      }
      return null;
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.volunteerId = id;
    this.getAllVolunteerActitivities(id);
    this.getVolunteer(this.volunteerId);
  },
  methods: {
    async computeFooter() {
      const sumall = this.activities
        .map((item) => item.hours)
        .reduce((prev, curr) => prev + curr, 0);
      this.balance = sumall;

      let activitiesType1 = this.activities.filter(function (activity) {
        return activity.activityTypeId == 1;
      });

      this.totalHours = activitiesType1
        .map((item) => item.hours)
        .reduce((prev, curr) => prev + curr, 0);

      let activitiesType2 = this.activities.filter(function (activity) {
        return activity.activityTypeId == 2;
      });

      this.numberOfDaysOff = activitiesType2.length;
    },
    async getVolunteer(id) {
      const { getVolunteer } = Volunteering;
      const response = await getVolunteer(id);
      const { data } = response;
      this.volunteer = data;
    },
    async getAllVolunteerActitivities(id) {
      const { getVolunteerActivities } = Volunteering;
      const response = await getVolunteerActivities(id);
      const { data } = response;
      this.activities = response.data;
      await this.computeFooter();
    },
    async handleDeleteVolunteerActivity(id) {
      const { deleteVolunteerActivity } = Volunteering;
      const response = await deleteVolunteerActivity(id);
      const { data, status } = response;
      if (status == "200") {
        const array = this.activities;
        const index = this.activities.findIndex((prop) => prop.id === id);
        this.activities.splice(index, 1);
        await this.computeFooter();
        this.$buefy.snackbar.open({
          message: "Inregistrea a fost stearsa.",
          queue: false,
        });
      }
    },
    handleAddVolunteerActivity() {
      this.$router.push({
        name: "volunteeractivity.add",
        params: { volunteerId: this.volunteerId },
      });
    },
    addDayOffModal() {
      this.addDayOffisModalActive = true;
      this.addDayOffObject = {
        activityTypeId: 2,
        volunteerId: this.volunteerId,
      };
    },
    trashPreemptionModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    async addDayOffConfirm() {
      this.addDayOffisModalActive = false;
      const { addVolunteerActivity } = Volunteering;
      const response = await addVolunteerActivity(this.addDayOffObject);
      const { data, status } = response;
      this.activities.push(data);
      await this.computeFooter();
    },
    trashConfirm() {
      this.isModalActive = false;
      this.handleDeleteVolunteerActivity(this.trashObject.id);
    },
    trashCancel() {
      this.isModalActive = false;
    },
    cancel() {
      this.$router.push({ path: `/volunteering/` });
    },
  },
};
</script>
